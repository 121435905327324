<template>
  <div>
    <CModal
      :show.sync="showModal"
      :close-on-backdrop="false"
      :centered="true"
      title="Modal title 2"
      size="lg"
      color="dark"
    >
      <label>UUID</label>
      <CInput
        type="text"
        :value="layout.uuid"
        readonly        
        class="validate_number"
      />
      <label class="required-field">Layout Name</label>
      <CInput
        type="text"
        v-model="name"
        placeholder="Enter layout name"
        class="validate_number"
      />
      <span v-if="$v.name && $v.name.$error" class="text-danger"
        >Name is required</span
      >
      <span v-if="lengthValidate" style="color: red">
        {{ lengthValidate }}
      </span>
      <!-- <CInput
        type="number"
        v-model="form.price"
        label="Price"
        min="1"
        oninput="this.value = Math.abs(this.value)"
        placeholder="Enter your price"
      />-->
      <template #header>
        <h6 class="modal-title">Layout Option</h6>
        <CButtonClose @click="onCloseModal()" class="text-white" />
      </template>
      <template #footer>
        <CButton @click.prevent="saveModal()" color="success">Save</CButton>
        <CButton @click="onCloseModal()" color="danger">Cancel</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import { postLayout, getLayout, putLayout } from "@/api/layout";
import { required } from "vuelidate/lib/validators";

export default {
  name: "layoutForm",
  props: {
    id: {
      type: Number,
      default: 0,
    },
    modalShow: {
      type: Boolean,
      default: false,
    },
    layout: {
      type: Object,
      default: {},
    },
    onHideModal: {
      type: Function,
    },
    uuid: {
      type: String
    }
  },
  created() {
    this.showModal = this.$props.modalShow;
    if (this.$props.id) {
      getLayout(this.$props.id)
        .then((res) => {
          this.form = res.data.data;
        })
        .catch((error) => {
          console.log(error, "error");
        });
    } else {
      this.name = "";
    }
  },
  data: () => {
    return {
      showModal: false,
      form: {
        name: "",
        // price: ""
      },
      name: "",
      errors: null,
      lengthValidate: "",
    };
  },
  watch: {
    layout: {
      immediate: true,
      handler(val) {
        console.log("value in modal", val);
        this.name = val.name;
      },
    },
  },
  methods: {
    changeModal() {
      if (!this.showModal) {
        this.onCloseModal();
      }
    },
    onCloseModal() {
      // this.form.name = "";
      this.name = "";
      this.showModal = false;
      this.$props.onHideModal();
    },
    saveModal() {
      console.log("this.form", this.name);
      let data = {
        name: this.name,
      };
      console.log("id", this.$props.id);
      if (this.$v.name.$error) {
        return;
      }
      if (this.$props.id) {
        putLayout(this.$props.id, data)
          .then((res) => {
            this.$emit("saveLayout", res.data.data, this.$props.id);
            this.$notify({
              group: "notify",
              type: "success",
              text: res.data.message,
            });
            this.onCloseModal();
          })
          .catch((error) => {
            console.log(error.response, "=");
            if (error.response.data.data.length) {
              this.lengthValidate = error.response.data.data.length[0];
            }
            this.$notify({
              group: "notify",
              type: "error",
              text: error.response.message,
            });
            this.onCloseModal();
            console.log(error, "error");
          });
      } else {
        postLayout(data)
          .then((res) => {
            console.log(res);
            this.$emit("saveLayout", res.data.data);
            this.$notify({
              group: "notify",
              type: "success",
              text: res.data.message,
            });
            this.onCloseModal();
          })
          .catch((error) => {
            console.log(error.response.data.data.name[0], "error");
            if (error.response.data.data.name) {
              this.lengthValidate = error.response.data.data.name[0];
            }
          });
      }
    },
  },
  validations: {
    name: {
      required,
    },
  },
};
</script>
