import request from "@/utils/request";

export function getAllLayout(page, perPage) {
  return request({
    url: "/api/layout-designs?perPage=" + perPage + "&page=" + page,
    method: "get"
  });
}

export function getLayout(id) {
  return request({
    url: "/api/layout-designs/" + id,
    method: "get"
  });
}

export function postLayout(data) {
  return request({
    url: "/api/layout-designs",
    method: "post",
    data
  });
}

export function putLayout(id, data) {
  console.log("data", data)
  return request({
    url: "/api/layout-designs/" + id,
    method: "put",
    data
  });
}

export function deleteLayout(id) {
  return request({
    url: "/api/layout-designs/" + id,
    method: "delete"
  });
}
